import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {RegisterOverviewComponent} from './register-overview/register-overview.component';
import {PersonalDataComponent} from './personal-data/personal-data.component';
import {BookingSuccessComponent} from './booking-success/booking-success.component';
import {AdminComponent} from './admin/admin.component';
import {PersonBookingComponent} from './person-booking/person-booking.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {AdminLoginComponent} from './admin-login/admin-login.component';
import {AdminAuthGuard} from './auth/AdminAuthGuard';
import {AdminPersonDetailComponent} from './admin-person-detail/admin-person-detail.component';
import {AdminPersonComponent} from './admin-person/admin-person.component';
import {AdminShiftsComponent} from './admin-shifts/admin-shifts.component';
import {AdminCheckinComponent} from './admin-checkin/admin-checkin.component';
import {AdminSettingsComponent} from './admin-settings/admin-settings.component';

const routes: Routes = [
  {path: '', component: WelcomeComponent},
  {path: 'register', component: RegisterOverviewComponent},
  {path: 'person', component: PersonalDataComponent},
  {path: 'success', component: BookingSuccessComponent},
  {path: 'personal-bookings', component: PersonBookingComponent},
  {path: 'admin', component: AdminComponent, canActivate: [AdminAuthGuard]},
  {path: 'admin-person', component: AdminPersonComponent, canActivate: [AdminAuthGuard]},
  {path: 'admin-person-detail', component: AdminPersonDetailComponent},
  {path: 'admin-shifts', component: AdminShiftsComponent},
  {path: 'admin-checkin', component: AdminCheckinComponent},
  {path: 'admin-settings', component: AdminSettingsComponent},
  {path: 'login', component: AdminLoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
