import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-comp-status-tag',
  templateUrl: './comp-status-tag.component.html',
  styleUrls: ['./comp-status-tag.component.css']
})
export class CompStatusTagComponent implements OnInit {

  @Input() status: string;

  constructor() {
    this.status = 'UNKNOWN';
  }

  ngOnInit(): void {
  }

}
