<div class="p-grid">
  <div class="p-col-12 p-sm-12 p-md-8 p-md-offset-2 p-lg-8 p-lg-offset-2 p-xl-6 p-xl-offset-3">
    <p-card>
      <div class="p-grid">
        <div class="p-sm-10">
          <h1>Staff {{eventlabel}}</h1>
          <h2>{{eventdate}}</h2>
        </div>
        <div class="p-sm-2">
          <img src="assets/anker.jpg" height="80">
        </div>
        <div class="p-sm-12">
          <label>Ohne Euch könnten wir einpacken! Umso dankbarer sind wir,
            dass Ihr seit Jahren das Openair mitgestaltet - oder dieses Jahr entschlossen seid, zum ersten Mal als
            Teil dieser wilden Truppe zu sein!
          </label>
          <br><br>
          <label>Wir alle arbeiten ehrenamtlich für das Openair - ausser unserer Liebe können wir Euch nicht viel
            zurückgeben. Trotzdem haben wir ein paar triftige Gründe, warum das Fest als Helfer:in umso toller ist:
          </label>
          <h4>Deine Vorteile als Helfer:in</h4>
          <ul>
            <li>Pro Tag kriegst Du eine Mahlzeit</li>
            <li>Pro Schicht gibt es einen Getränkegutschein, einlösbar an einer beliebigen Bar</li>
            <li>Ein T-Shirt, welches Du behalten kannst</li>
            <li>Zutritt zum GZ</li>
			<ul>
				<li>Aufenthaltsraum mit kostenlosen Snacks und Getränken</li>
				<li>Saubere Toiletten</li>
				<li>Garderobe</li>
			</ul>
            <li>Eine Staff-Party nach dem Openair</li>
            <!--<li>Ab zwei Schichten: Gästeliste und Gratiseintritt an der After-Party in der Roten Fabrik</li>-->
          </ul>
          <p>Weitere gute Gründe</p>
          <ul>
            <li>Helfen macht glücklich</li>
            <li>Karma-Kontostand aufbessern</li>
            <li>Traditionell cooles Helfer:innen-Team</li>
          </ul>
          <h4>So funktioniert's</h4>
          <label>Du kannst Dich direkt für eine Schicht anmelden. Auf der nächsten Seite siehst Du alle offenen Schichten und wie viele freie Plätze es noch hat.
            Wenn ihr als Gruppe zusammen eingeteilt sein möchtet, sprecht euch direkt ab und wählt die gleiche Schicht.</label>
          <br><br>
          <label>Bei Fragen kannst Du uns jederzeit unter <a href="mailto:staff@openair-wollishofen.ch">staff@openair-wollishofen.ch</a> kontaktieren.</label>
          <br><br>
        </div>
        <div class="p-col-12" align="right">
          <button pButton pRipple type="button" label="Los geht's" class="p-button-success"
                  (click)="go()"></button>
        </div>
      </div>
    </p-card>
  </div>
</div>
