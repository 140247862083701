<div class="p-grid">
  <div class="p-col-12 p-sm-12 p-md-8 p-md-offset-2 p-lg-8 p-lg-offset-2 p-xl-6 p-xl-offset-3">
    <p-card>
      <div class="p-grid">
        <div class="p-sm-10">
          <h1>Anmeldung {{eventlabel}}</h1>
          <h2>{{eventdate}}</h2>
        </div>
        <div class="p-sm-2">
          <img src="assets/anker.jpg" height="80">
        </div>
      </div>
      <div class="p-sm-12">
        <!--<label>Es freut uns, dass Du uns als Helfer:in am Openair Wollishofen unterstützen möchtest</label><br>-->
        <p-message *ngIf="validityError.noShifts" severity="error"
                   text="Du hast keine Schicht ausgewählt. Bitte wähle im ersten Schritt mindestens eine Schicht aus."></p-message>
        <p-message *ngIf="registerError" severity="error"
                   text="Fehler bei der Registrierung: {{registerErrorMessage}}"></p-message>
        <div class="grid p-fluid">
          <div class="field p-mb-4 p-mt-4">
            <label for="firstname" class="block">Vorname*</label>
            <input autofocus id="firstname" type="firstname" aria-describedby="firstname-help" [(ngModel)]="person.firstname"
                   [ngClass]="validityError.firstname ? 'ng-invalid ng-dirty': ''" pInputText/>
            <small *ngIf="validityError.firstname" id="firstname-help" class="p-error block">Vorname fehlt</small>
          </div>
          <div class="field p-mb-4">
            <label for="lastname" class="block">Nachname*</label>
            <input id="lastname" type="lastname" aria-describedby="lastname-help" [(ngModel)]="person.lastname"
                   [ngClass]="validityError.lastname ? 'ng-invalid ng-dirty': ''" pInputText/>
            <small *ngIf="validityError.lastname" id="lastname-help" class="p-error block">Nachname fehlt</small>
          </div>
          <div class="field p-mb-4">
            <label for="email" class="block">E-Mail*</label>
            <input id="email" type="email" aria-describedby="email-help" [(ngModel)]="person.email"
                   [ngClass]="validityError.email ? 'ng-invalid ng-dirty': ''" pInputText/>
            <small *ngIf="validityError.email" id="email-help" class="p-error block">Bitte gebe eine korrekte
              E-Mail-Adresse an.</small>
          </div>
		  <div class="field p-mb-4">
            <label for="email" class="block">E-Mail wiederholen</label>
            <input id="email" type="email" aria-describedby="email-help" [formControl]="emailRepeat"
                   [ngClass]="validityError.emailRepeat ? 'ng-invalid ng-dirty': ''" pInputText/>
            <small *ngIf="validityError.emailRepeat" id="email-help" class="p-error block">Die E-Mail stimmt nicht überein.</small>
          </div>
          <div class="field p-mb-4">
            <label for="mobile" class="block">Mobile</label>
            <input id="mobile" type="mobile" [(ngModel)]="person.mobile" pInputText/>
          </div>
          <div class="field p-mb-4">
            <label>Bist Du über 18 Jahre alt?</label>
            <p-selectButton [options]="ageOptions" [(ngModel)]="person.over18" optionLabel="label"
                            optionValue="value"></p-selectButton>
          </div>
          <div class="field p-mb-4">
            <label for="shirtSize" class="block">T-Shirt-Grösse</label>
            <p-dropdown [options]="shirtSizes" [(ngModel)]="person.shirtSize"
                        optionLabel="label" optionValue="size"></p-dropdown>
          </div>
          <div class="field p-mb-4">
            <label for="comment" class="block">Kommentar</label>
            <input id="comment" type="comment" [(ngModel)]="person.comment" pInputText/>
          </div>
        </div>
      </div>
      <div class="p-sm-12">
		<p-checkbox [(ngModel)]="person.acceptedUV" [binary]="true" inputId="uvCheckbox"></p-checkbox>
        <label for="uvCheckbox"> Hiermit bestätige ich, dass ich während meiner Teilnahme als Helfer:in unfallversichert bin*.</label>
		<br/>
		<small *ngIf="validityError.uvAccepted" id="uvAccepted" class="p-error block">Du musst dies mit einem Häkchen bestätigen</small>
      </div>
	  <p class="p-sm-12" style="margin: 0;">*Damit du mithelfen darfst, benötigst du eine Unfallversicherung (UV). 
		Wenn Du über deinen Arbeitgeber KEINE UV hast, musst Du über deine Krankenkasse eine UV abschliessen.</p>
      <div class="p-grid p-mb-4 p-mt-4">
        <div class="p-col-4">
          <button pButton pRipple type="button" label="Zurück" class="p-button-outlined" (click)="goBack()"></button>
        </div>
        <div class="p-col-8" align="right">
          <button pButton pRipple type="button" label="Definitiv anmelden" class="p-button-success" (click)="register()"
                  [loading]="btnLoading"></button>
        </div>
      </div>
    </p-card>
  </div>
</div>
