import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-admin-person-detail',
  templateUrl: './admin-person-detail.component.html',
  styleUrls: ['./admin-person-detail.component.css']
})
export class AdminPersonDetailComponent implements OnInit {

  public personPublicKey: string | undefined;

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {

      this.personPublicKey = params['uuid'];

    });
  }

  ngOnInit(): void {
  }

}
