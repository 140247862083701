<p-message *ngIf="loadingError" severity="error"
           text="Fehler beim Laden der Schichten. Probiere es später noch einmal."></p-message>
<div *ngFor="let group of groupedShifts">
  <h4>{{group[0].outlet.label}}</h4>
  <div *ngFor="let shift of group">
    <div *ngIf="shift" class="p-grid">
      <div *ngIf="shift.duration" class="p-col-4">
        <label>{{shift.duration.startTime | dateTimeToFormat:'EEE, HH:mm'}}
          - {{shift.duration.endTime | dateTimeToFormat:'HH:mm'}} Uhr</label>
      </div>
      <div class="p-col-3">
        <p-progressBar [value]="getOccupancy(shift.occupancy)" [showValue]="false"></p-progressBar>
        <label>Anz: {{shift.occupancy.bookedTotal}} Min: {{shift.occupancy.min}}, Max: {{shift.occupancy.max}} </label>
      </div>
      <div class="p-col-3">
        <p-progressBar [value]="getOccupancyCheckIn(shift.occupancy)" [showValue]="false"></p-progressBar>
        <label>Eingechecked: {{shift.occupancy.checkedInTotal}} </label>

      </div>
      <div class="p-col-2">
        <button pButton pRipple type="button" label="Details" class="p-button-primary"
                (click)="selectShiftDetail(shift)"></button>

      </div>
    </div>
  </div>
</div>
