<div class="p-grid">
  <div class="p-col-12 p-sm-12 p-md-8 p-md-offset-2 p-lg-8 p-lg-offset-2 p-xl-6 p-xl-offset-3">
    <p-card>
      <div class="p-grid">
        <div class="p-sm-10">
          <h1>Bestätigung {{eventlabel}}</h1>
          <h2>{{eventdate}}</h2>
        </div>
        <div class="p-sm-2">
          <img src="assets/anker.jpg" height="80">
        </div>
      </div>
      <label>Herzlichen Dank für deine Anmeldung!</label>
      <br><br>
      <label>Wir werden deine Anmeldung in den nächsten Tagen überprüfen
        und dir die definitive Einteilung per Mail bestätigen.</label>
      <br><br>
      <p-message *ngIf="loadingError" severity="error" text="Fehler beim Laden deiner Daten."></p-message>
      <p-message *ngIf="noPublicKey" severity="error"
                 text="Fehler beim Laden deiner Daten: Bitte gebe einen publicKey an."></p-message>
      <div class="p-grid">
        <div *ngIf="person && !loadingError && !noPublicKey" class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6">
          <div *ngIf="person">
            <h4>Deine Personalien</h4>
            <label>{{person.firstname}} {{person.lastname}}</label><br>
            <label>{{person.email}}</label><br>
            <label>{{person.mobile}}</label><br><br>
            <label>Shirt-Grösse: {{getShirtSize()}}</label><br>
            <label>Über 18: {{booleanHR(person.over18)}}</label><br>
            <label>Kommentar: {{person.comment}}</label><br>
          </div>
        </div>
        <div *ngIf="!loadingError && !noPublicKey" class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6">
          <h4>Deine Schichten</h4>
          <p-listbox [options]="bookings">
            <ng-template let-booking pTemplate="item">
                <div class="p-grid">
                  <div class="p-sm-12">
                    <b>{{booking.shift.duration.startTime | dateTimeToFormat:'dd.MM.yyyy'}}</b>
                  </div>
                  <div class="p-sm-12">
                    {{booking.shift.outlet.label}}
                  </div>
                  <div class="p-sm-12">
                    {{booking.shift.duration.startTime | dateTimeToFormat:'HH:mm'}} - {{booking.shift.duration.endTime | dateTimeToFormat:'HH:mm'}} Uhr
                  </div>
                </div>
            </ng-template>
          </p-listbox>
        </div>
      </div>
    </p-card>
  </div>
</div>
