<!--<p-table [value]="shifts" responsiveLayout="scroll">
  <ng-template pTemplate="header">
    <tr>
      <th>Zeltbar</th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-shift>
    <tr *ngIf="shift">
      <td>{{shift.outlet.label}}</td>
      <td>{{shift.startEnd.startDateTime | dateTimeToFormat:'EEE, dd.MM.yyyy'}}</td>
      <td>{{shift.startEnd.startDateTime | dateTimeToFormat:'HH:mm'}}
        - {{shift.startEnd.endDateTime | dateTimeToFormat:'HH:mm'}} Uhr
      </td>
      <td>{{shift.activity.label}}</td>
      <td>
        <p-progressBar [value]="30"></p-progressBar>
      </td>
      <td>
        <p-button *ngIf="!shift.selected" label="Wählen" class="p-button-success" (onClick)="selectShift(shift.uuid)"></p-button>
        <p-tag *ngIf="shift.selected"  styleClass="mr-2" icon="pi pi-check" severity="success" value="Gewählt"></p-tag>
        <button *ngIf="shift.selected" pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="unselectShift(shift.uuid)"></button>
      </td>
    </tr>  </ng-template>
</p-table>-->
<p-message *ngIf="loadingError" severity="error"
           text="Fehler beim Laden der Schichten. Probiere es später noch einmal."></p-message>
<div class="field-checkbox">
  <p-checkbox [(ngModel)]="onlyFreeShifts" [binary]="true" inputId="checkOnlyFree" (onChange)="onlyFreeChanged()"></p-checkbox>
  <label for="checkOnlyFree"> Nur freie Schichten anzeigen</label>
</div>
<div *ngFor="let group of groupedShifts">
  <h4>{{group[0].outlet.label}}</h4>
  <app-shift-item *ngFor="let shift of group" [shift]="shift" [adminView]="adminView" (onSelectChange)="itemChanged($event)"></app-shift-item>
</div>
