<div class="grid">
  <div class="p-col-12">
    <p-message *ngIf="loadingError" severity="error" text="Fehler beim Speichern"></p-message>
  </div>
  <div class="p-col-12">
    <div class="grid p-fluid">
      <div class="field p-mb-4 p-mt-4">
        <label for="outlet">Standort</label>
        <p-dropdown id="outlet" [options]="outlets" [(ngModel)]="selectedOutletUuid" placeholder="Standort"
                    optionLabel="label" optionValue="uuid" [showClear]="true"></p-dropdown>
      </div>
      <div class="field p-mb-4 p-mt-4">
        <label for="activities">Aktivität</label>
        <p-dropdown id="activities" [options]="activities" [(ngModel)]="selectedActivityUuid" placeholder="Aktivität"
                    optionLabel="label" optionValue="uuid" [showClear]="true"></p-dropdown>
      </div>
      <div class="field p-mb-4 p-mt-4">
        <label for="durations">Zeit</label>
        <p-dropdown id="durations" [options]="durations" [(ngModel)]="selectedDurationUuid" placeholder="Zeit"
                    optionLabel="startTime" optionValue="uuid" [showClear]="true"></p-dropdown>
      </div>
      <div class="field p-mb-4 p-mt-4">
        <label for="min">Anz. Min</label>
        <input id="min" [(ngModel)]="selectedOccupancyMin" type="number" pInputText class="p-inputtext-sm"
               placeholder="Small">
      </div>
      <div class="field p-mb-4 p-mt-4">
        <label for="max">Anz. Max</label>
        <input id="max" [(ngModel)]="selectedOccupancyMax" type="number" pInputText class="p-inputtext-sm"
               placeholder="Small">
      </div>
      <button pButton pRipple type="button" class="p-button-success"
              icon="pi pi-save"
              label="Speichern"
              (click)="createShift()">
      </button>
    </div>
  </div>
</div>
