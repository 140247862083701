import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {tap} from 'rxjs/operators';
import {DateTime} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router) {

  }

  setSession(jwt: string, expiresSec: number, username: string): void {
    const expiresAt = DateTime.now().plus({seconds: expiresSec});

    localStorage.setItem('jwt_token', jwt);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.toISO()));
    localStorage.setItem('username', username);
  }

  clearSession(): void {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('username');
  }

  logout(): void {
    this.clearSession();
    this.router.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    const expires = this.getExpiration();
    if (expires === undefined) {
      return false;
    }
    return expires >= DateTime.now();
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  getToken(): string | undefined {
    const token = localStorage.getItem('jwt_token');
    if (token != null) {
      return token;
    }
    return undefined;
  }

  private getExpiration(): DateTime | undefined {
    const expiration = localStorage.getItem('expires_at');
    if (expiration != null) {
      const expiresAt = JSON.parse(expiration);
      return DateTime.fromISO(expiresAt);
    }
    return undefined;
  }
}
