import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {RegistrationResponse} from '../domain/RegistrationResponse';
import {environment} from '../../environments/environment';
import {Shift} from '../domain/Shift';
import {Duration} from '../domain/Duration';
import {DateTime} from 'luxon';
import {Booking} from '../domain/Booking';
import {Person} from '../domain/Person';

@Component({
  selector: 'app-person-booking',
  templateUrl: './person-booking.component.html',
  styleUrls: ['./person-booking.component.css']
})
export class PersonBookingComponent implements OnInit {

  publicKey: string | undefined;
  noPublicKey = true;
  loadingError = false;

  person: Person | undefined;
  bookings: Booking [] = [];

  eventdate = environment.labels.eventdate;
  eventlabel = environment.labels.eventlabel;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute) {
    this.noPublicKey = true;
    this.activatedRoute.queryParams.subscribe(params => {
      this.publicKey = params['publicKey'];
      if (this.publicKey !== undefined) {
        this.loadPerson();
        this.noPublicKey = false;
      }
    });
  }

  ngOnInit(): void {
  }

  loadPerson(): void {
    this.loadingError = false;
    this.httpClient.get<RegistrationResponse>(environment.serverUrl + '/registration?publicKey=' + this.publicKey).subscribe(result => {
        this.person = result.person;

        this.bookings = [];

        result.bookings.forEach(re => {
          const shift = new Shift(re.shift.uuid, re.shift.outlet, re.shift.activity,
            new Duration(re.shift.duration.uuid, DateTime.fromISO(re.shift.duration.startTime), DateTime.fromISO(re.shift.duration.endTime)),
            re.shift.occupancy, re.visible, true, true);
          this.bookings.push(new Booking(re.uuid, re.personalUuid, shift, re.status, re.checkedIn, re.deleted,
            DateTime.fromISO(re.createdAt), DateTime.fromISO(re.updatedAt)
          ));
        });
      },
      error => {
        this.loadingError = true;
      });
  }

  confirmBooking(bookingUuid: string): void {
    const body = {
      bookingUuid: bookingUuid
    };

    this.httpClient.post<any>(environment.serverUrl + '/persons/' + this.publicKey + '/status/confirm-staff', body).subscribe(
      result => {
        this.loadPerson();
      },
      error => {
        // ToDo
      }
    );
  }
}
