import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {Shift} from '../domain/Shift';
import {Duration} from '../domain/Duration';
import {DateTime} from 'luxon';
import {Occupancy} from '../domain/Occupancy';
import {HttpClient} from '@angular/common/http';
import {from} from 'rxjs';
import {groupBy, mergeMap, toArray} from 'rxjs/operators';
import {Person} from '../domain/Person';
import {PersonBooking} from '../domain/PersonBooking';

@Component({
  selector: 'app-admin-checkin',
  templateUrl: './admin-checkin.component.html',
  styleUrls: ['./admin-checkin.component.css']
})
export class AdminCheckinComponent implements OnInit {

  personBookings = new Map<string, PersonBooking[]>();
  shifts: Shift[] = [];
  groupedShifts: any[] = [];

  loadingError = false;

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit(): void {
    this.loadShifts();
  }

  loadShifts(): void {
    this.shifts = [];
    this.personBookings = new Map();
    this.loadingError = false;

    this.httpClient.get<any[]>(environment.serverUrl + '/shifts').subscribe(result => {
      this.shifts = [];

      result.forEach(re => {
        this.shifts.push(new Shift(re.uuid, re.outlet, re.activity,
          new Duration(re.duration.uuid, DateTime.fromISO(re.duration.startTime), DateTime.fromISO(re.duration.endTime)),
          new Occupancy(re.occupancy.bookedTotal, re.occupancy.checkedInTotal, new Map(Object.entries(re.occupancy.numbersByStatus)),
            re.occupancy.min, re.occupancy.max), re.visible, false, true));

        this.loadShiftDetails(re.uuid);
      });

      this.groupShifts();

    }, error => {
      this.loadingError = true;
    });
  }

  loadShiftDetails(shiftUuid: string): void {

    this.httpClient.get<PersonBooking[]>(environment.serverUrl + '/registration/of-shift?shiftUuid=' + shiftUuid).subscribe(
      result => {
        this.personBookings.set(shiftUuid, result);
      }, error => {
        // ToDo
      }
    );
  }

  getPersonBookingOfShift(shiftUuid: string): PersonBooking[] {
    const result = this.personBookings.get(shiftUuid);

    if (result === undefined) {
      return [];
    }

    return result;
  }

  private groupShifts(): any {
    this.shifts.sort((a: Shift, b: Shift) => a.duration.startTime.toMillis() - b.duration.startTime.toMillis());
    this.groupedShifts = [];
    from(this.shifts)
      .pipe(
        groupBy(
          shift => shift.duration.uuid
        ),
        mergeMap(group => group.pipe(toArray()))
      )
      .subscribe(result => this.groupedShifts.push(result));
  }

  getOccupancyCheckIn(occ: Occupancy): any {
    if (!occ) {
      return 0;
    }
    return Math.round(occ.checkedInTotal / occ.max * 100.0 * 10.0) / 10.0;
  }

  checkIn(bookingUuid: string, shiftUuid: string): void {
    this.httpClient.post<any>(environment.serverUrl + '/registration/' + bookingUuid + '/check-in', {}).subscribe(
      result => {
        const personBooking = this.personBookings.get(shiftUuid);

        if (personBooking) {
          const personBookingFilter = personBooking.find(pB => {
            if (pB && pB.booking) {
              return pB.booking.uuid === bookingUuid;
            } else {
              return false;
            }
          });

          if (personBookingFilter) {
            personBookingFilter.booking.checkedIn = true;
          }
        }

        // @ts-ignore
        this.groupedShifts.forEach(group => group.forEach(shift => {
          if (shift.uuid === shiftUuid) {
            shift.occupancy.checkedInTotal += 1;
          }
        }));
      },
      error => {
        // ToDo
      }
    );
  }

  checkOut(bookingUuid: string, shiftUuid: string): void {
    this.httpClient.post<any>(environment.serverUrl + '/registration/' + bookingUuid + '/check-out', {}).subscribe(
      result => {
        const personBooking = this.personBookings.get(shiftUuid);

        if (personBooking) {
          const personBookingFilter = personBooking.find(pB => {
            if (pB && pB.booking) {
              return pB.booking.uuid === bookingUuid;
            } else {
              return false;
            }
          });

          if (personBookingFilter) {
            personBookingFilter.booking.checkedIn = false;
          }
        }

        // @ts-ignore
        this.groupedShifts.forEach(group => group.forEach(shift => {
          if (shift.uuid === shiftUuid) {
            shift.occupancy.checkedInTotal -= 1;
          }
        }));
      },
      error => {
        // ToDo
      });
  }
}
