<div *ngIf="loadedPerson" class="p-grid">
  <div class="p-col-12 p-sm-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6">
        <button icon="pi pi-arrow-left" styleClass="p-button-rounded p-button-secondary p-button-text p-button-raised" pButton type="button" (click)="doBack()"></button>
      </div>
      <div class="p-field p-col-6">
        <h3>{{loadedPerson.firstname}} {{loadedPerson.lastname}}</h3>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6">
    <p-card header="Kontakt">
      <label>{{loadedPerson.firstname}} {{loadedPerson.lastname}}</label><br>
      <label>{{loadedPerson.email}}</label><br>
      <label>{{loadedPerson.mobile}}</label><br><br>
      <label>Shirt-Grösse: {{getShirtSize(loadedPerson.shirtSize)}}</label><br>
      <label>Über 18: {{booleanHR(loadedPerson.over18)}}</label><br>
      <label>Kommentar: {{loadedPerson.comment}}</label><br>
    </p-card>
  </div>
  <div *ngIf="loadedBookings" class="p-col-12 p-sm-12 p-md-12 p-lg-6  p-xl-6">
    <p-card header="Schichten">
      <button *ngIf="loadedPerson.shouldSendBookingChangedInfo" pButton pRipple type="button"
              label="Person über Änderungen informieren (per Mail)" class="p-button-warning"
              (click)="informPersonAboutChanges()"></button>
      <br><br>
      <p-listbox [options]="loadedBookings">
        <ng-template let-booking pTemplate="item">
          <div class="p-grid">
            <div class="p-col-12">
              <b>{{booking.shift.duration.startTime | dateTimeToFormat:'dd.MM.yyyy'}}</b>
            </div>
            <div class="p-col-12">
              {{booking.shift.outlet.label}}
            </div>
            <div class="p-col-12">
              {{booking.shift.duration.startTime | dateTimeToFormat:'HH:mm'}}
              - {{booking.shift.duration.endTime | dateTimeToFormat:'HH:mm'}} Uhr
            </div>
            <div class="p-col-6">
              <app-comp-status-tag [status]="booking.status"></app-comp-status-tag>
            </div>
            <div class="p-col-6">
              <p-tag *ngIf="booking.checkedIn" severity="success" value="Eingechecked"></p-tag>
              <p-tag *ngIf="!booking.checkedIn" severity="warning" value="Nicht-Eingechecked"></p-tag>
            </div>
            <div class="p-col-6">
              <button pButton pRipple type="button"
                      label="Löschen" class="p-button-danger p-button-sm"
                      (click)="showDeleteDialog(booking.uuid)"></button>
            </div>
            <div class="p-col-6">
              <button *ngIf="!booking.checkedIn" pButton pRipple type="button"
                      label="Einchecken" class="p-button-warning p-button-sm" (click)="checkIn(booking.uuid)"></button>
              <button *ngIf="booking.checkedIn" pButton pRipple type="button"
                      label="Auschecken" class="p-button-primary p-button-sm" (click)="checkOut(booking.uuid)"></button>
            </div>
          </div>
        </ng-template>
      </p-listbox>
      <br>
      <button pButton pRipple type="button"
              label="Schicht hinzufügen" class="p-button-success" (click)="showDialogAddShift()"></button>
      <br><br>
      <button *ngIf="loadedPerson.minShiftStatus === 'NEW'" pButton pRipple type="button"
              label="Alle neuen Schichten bestätigen und E-Mail senden" class="p-button-success"
              (click)="confirmBookings()" [loading]="btnLoading"></button>
    </p-card>
  </div>

  <div class="p-col-12 p-sm-12 p-md-12 p-lg-6  p-xl-6">
    <p-card header="History">
      <ul>
        <li *ngFor="let history of loadedHistory">
          {{history.createdAt | dateTimeToFormat:'dd.MM.yyyy HH:mm'}}
          {{getStatusStr(history.status)}}
          <label *ngIf="history.emailSent"> (Mail verschickt)</label>
          <label *ngIf="!history.success"><b>Fehler beim Versenden des Mails: {{history.errorMessage}}</b></label>
        </li>
      </ul>
      <div *ngIf="loadedPerson" class="p-col-12 p-sm-12 p-md-12 p-lg-6  p-xl-6">
        <button *ngIf="loadedPerson.minShiftStatus === 'CONFIRMED_BY_ADMIN'" pButton pRipple type="button"
                label="Erinnerungsmail verschicken" class="p-button-primary p-button-sm"
                (click)="sendReminder(loadedPerson.publicKey)"></button>
      </div>
      <!--<p-timeline [value]="loadedHistory">
        <ng-template pTemplate="content" let-history>
          {{history.status}}
        </ng-template>
      </p-timeline>-->
    </p-card>
  </div>
</div>
<p-dialog header="Buchung löschen" [(visible)]="dialogDeleteBookingVisible">
  <div class="p-field">
    <label>Bist Du sicher, dass die Schicht gelöscht werden soll?</label><br><br>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple type="button" class="p-button-danger" label="Ja"
            (click)="deleteBooking()"></button>
  </ng-template>
</p-dialog>

<p-dialog header="Buchung hinzufügen" [(visible)]="dialogAddShiftVisible">
  <div class="p-col-12">
    <app-shift-list (onSelectedShiftChanged)="addShift($event)"></app-shift-list>
  </div>
</p-dialog>
