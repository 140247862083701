import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Person} from '../domain/Person';
import {Booking} from '../domain/Booking';
import {HistoryEntry} from '../domain/HistoryEntry';
import {environment} from '../../environments/environment';
import {RegistrationResponse} from '../domain/RegistrationResponse';
import {Shift} from '../domain/Shift';
import {Duration} from '../domain/Duration';
import {DateTime} from 'luxon';
import {HttpClient} from '@angular/common/http';
import { Location } from '@angular/common';

@Component({
  selector: 'app-comp-person-detail',
  templateUrl: './comp-person-detail.component.html',
  styleUrls: ['./comp-person-detail.component.css']
})
export class CompPersonDetailComponent implements OnInit, OnChanges {

  @Input() public publicKey: string | undefined;

  loadedPerson: Person | undefined;
  loadedBookings: Booking[] = [];
  loadedHistory: HistoryEntry[] = [];

  dialogDeleteBookingVisible = false;
  bookingUuidToDelete: string | undefined;

  dialogAddShiftVisible = false;

  btnLoading = false;

  constructor(public httpClient: HttpClient, private location: Location) { }

  ngOnInit(): void {
    this.loadPerson();
  }

  ngOnChanges(): void {
    this.loadPerson();
  }

  loadPerson(): void {
    this.loadedPerson = undefined;
    this.loadedBookings = [];

    if (this.publicKey === undefined) {
      return;
    }

    this.httpClient.get<RegistrationResponse>(environment.serverUrl + '/registration?publicKey=' + this.publicKey)
      .subscribe(result => {
          this.loadedPerson = result.person;
          this.loadedBookings = [];

          result.bookings.forEach(re => {
            const shift = new Shift(re.shift.uuid, re.shift.outlet, re.shift.activity,
              new Duration(re.shift.duration.uuid, DateTime.fromISO(re.shift.duration.startTime),
                DateTime.fromISO(re.shift.duration.endTime)), re.shift.occupancy, re.visible, true, true);
            this.loadedBookings.push(new Booking(re.uuid, re.personalUuid, shift, re.status, re.checkedIn, re.deleted,
              DateTime.fromISO(re.createdAt), DateTime.fromISO(re.updatedAt)
            ));
          });

          this.loadHistory();
        },
        error => {
          // ToDo
        });
  }

  sendReminder(publicKey: string | undefined): void {
    this.httpClient.post<any>(environment.serverUrl + '/persons/' + this.publicKey + '/status/send-confirmation-reminder', {})
      .subscribe(result => {
          this.loadPerson();
        },
        error => {
          // ToDo
        });
  }

  loadHistory(): void {
    this.loadedHistory = [];

    if (this.publicKey === undefined) {
      return;
    }

    this.httpClient.get<any[]>(environment.serverUrl + '/persons/' + this.publicKey + '/history').subscribe(
      result => {
        result.forEach(entry => this.loadedHistory.push(
          new HistoryEntry(entry.uuid, entry.personUuid, entry.status, entry.emailSent, entry.success, entry.errorMessage,
            entry.referenceUuid, DateTime.fromISO(entry.createdAt))));
        console.log(this.loadedHistory);
      }
      ,
      error => {
        // ToDo
      }
    );
  }

  getShirtSize(size: any): string {
    if (size === 'SMALL') {
      return 'S';
    }
    if (size === 'MEDIUM') {
      return 'M';
    }
    if (size === 'LARGE') {
      return 'L';
    }
    if (size === 'XLARGE') {
      return 'XL';
    }
    return 'Unbekannt';
  }

  booleanHR(bool: boolean): string {
    if (bool) {
      return 'Ja';
    }
    return 'Nein';
  }

  informPersonAboutChanges(): void {
    if (this.publicKey === undefined) {
      return;
    }

    this.httpClient.post<any>(environment.serverUrl + '/persons/' + this.publicKey + '/status/send-changes', {})
      .subscribe(result => {
          this.loadPerson();
        },
        error => {
          // ToDo
        });
  }

  showDialogAddShift(): void {
    this.dialogAddShiftVisible = true;
  }

  showDeleteDialog(bookingUuid: string): void {
    this.bookingUuidToDelete = bookingUuid;
    this.dialogDeleteBookingVisible = true;
  }

  getStatusStr(status: string): string {
    if (status === 'REGISTRATION') {
      return 'Anmeldung';
    }
    if (status === 'CONFIRMED_BY_ADMIN') {
      return 'Schichten vom Admin bestätigt';
    }
    if (status === 'BOOKING_CONFIRMED_BY_STAFF') {
      return 'Schichten vom Staff bestätigt';
    }
    if (status === 'BOOKING_DELETED') {
      return 'Schicht gelöscht';
    }
    if (status === 'ADD_BOOKING') {
      return 'Schicht hinzugefügt';
    }
    if (status === 'INFORMED_ABOUT_CHANGES') {
      return 'Über Änderung informiert';
    }
    if (status === 'BOOKING_CHECKIN') {
      return 'Eingechecked';
    }
    if (status === 'BOOKING_CHECKOUT') {
      return 'Ausgechecked';
    }
    if (status === 'CONFIRMATION_REMINDER') {
      return 'Erinnerungsmail verschickt';
    }
    return ' Unbekannt';
  }

  deleteBooking(): void {
    this.httpClient.delete(environment.serverUrl + '/registration/' + this.bookingUuidToDelete).subscribe(result => {
      this.loadPerson();
    }, error => {
      // ToDo
    });

    this.dialogDeleteBookingVisible = false;
    this.bookingUuidToDelete = undefined;
  }

  addShift(shiftUuids: string[]): void {

    if (shiftUuids.length === 0) {
      return;
    }

    this.dialogAddShiftVisible = false;

    const body = {
      personUuid: this.loadedPerson?.uuid,
      shiftUuid: shiftUuids[0]
    };

    this.httpClient.post(environment.serverUrl + '/registration/add-booking', body).subscribe(
      result => {
        this.loadPerson();
      },
      error => {
        // ToDo
      }
    );
  }

  confirmBookings(): void {
    const publicKey = this.loadedPerson?.publicKey;
    if (publicKey === undefined) {
      return;
    }

    this.btnLoading = true;
    this.httpClient.post<any>(environment.serverUrl + '/persons/' + publicKey + '/status/confirm-admin', {}).subscribe(result => {
        this.loadPerson();
        this.btnLoading = false;
      },
      error => {
        // ToDo
        this.btnLoading = false;
      });
  }

  checkIn(bookingUuid: string): void {
    this.httpClient.post<any>(environment.serverUrl + '/registration/' + bookingUuid + '/check-in', {}).subscribe(
      result => this.loadPerson(),
      error => this.loadPerson()
    );
  }

  checkOut(bookingUuid: string): void {
    this.httpClient.post<any>(environment.serverUrl + '/registration/' + bookingUuid + '/check-out', {}).subscribe(
      result => this.loadPerson(),
      error => this.loadPerson()
    );
  }

  doBack(): void {
    this.location.back();
  }
}
