import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Outlet} from '../domain/Outlet';
import {Activity} from '../domain/Activity';
import {Duration} from '../domain/Duration';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-create-shift',
  templateUrl: './dialog-create-shift.component.html',
  styleUrls: ['./dialog-create-shift.component.css']
})
export class DialogCreateShiftComponent implements OnInit {

  loadingError = false;

  // Meta
  outlets: Outlet[] = [];
  activities: Activity[] = [];
  durations: Duration[] = [];

  // New Shift
  selectedOutletUuid = '';
  selectedActivityUuid = '';
  selectedDurationUuid = '';
  selectedOccupancyMin = 0;
  selectedOccupancyMax = 10;


  constructor(private httpClient: HttpClient, public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.loadOutlets();
    this.loadActivities();
    this.loadDurations();
  }

  loadOutlets(): void {
    this.httpClient.get<Outlet[]>(environment.serverUrl + '/shifts/outlets').subscribe(result => {
      this.outlets = result;
    }, error => {
      this.outlets = [];
    });
  }

  loadActivities(): void {
    this.httpClient.get<Activity[]>(environment.serverUrl + '/shifts/activities').subscribe(result => {
      this.activities = result;
      console.log(JSON.stringify(result));
    }, error => {
      this.activities = [];
    });
  }

  loadDurations(): void {
    this.httpClient.get<Duration[]>(environment.serverUrl + '/shifts/durations').subscribe(result => {
      this.durations = result;
      console.log(JSON.stringify(result));
    }, error => {
      this.durations = [];
    });
  }

  createShift(): void {

    const body = {
      outletUuid: this.selectedOutletUuid,
      activityUuid: this.selectedActivityUuid,
      durationUuid: this.selectedDurationUuid,
      occupancyMin: this.selectedOccupancyMin,
      occupancyMax: this.selectedOccupancyMax
    };

    this.httpClient.post<any>(environment.serverUrl + '/shifts', body).subscribe(
      result => {
        this.ref.close();
      },
      error => {
        this.loadingError = true;
      }
    );
  }

}
