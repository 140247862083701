<app-navigation></app-navigation>
<div class="p-grid">
  <div class="p-col-6">
    <p-card>
      <div class="p-col-12">
        <h3>Schichten</h3>
      </div>
      <div class="p-col-12">
        <app-shift-list-admin (onSelectedShiftChanged)="selectedShiftChange($event)"></app-shift-list-admin>
      </div>
    </p-card>
  </div>
  <div *ngIf="selectedShift" class="p-col-6">
    <p-card>
      <div class="p-col-12">
        <h3>{{selectedShift.outlet.label}}</h3>
        <h5>{{selectedShift.duration.startTime | dateTimeToFormat:'EEE, HH:mm'}}
          - {{selectedShift.duration.endTime | dateTimeToFormat:'HH:mm'}} Uhr</h5>
      </div>
      <div class="p-col-12">
        <p-listbox [options]="personBookings">
          <ng-template let-personBooking pTemplate="item">
            <div class="p-grid">
              <div class="p-col-12">
                <label><b>{{personBooking.person.firstname}} {{personBooking.person.lastname}}</b></label><br>
                <app-comp-status-tag [status]="personBooking.booking.status"></app-comp-status-tag>
              </div>
              <div class="p-col-6">
                <button *ngIf="!personBooking.booking.checkedIn" pButton pRipple type="button" label="Einchecken" class="p-button-warning"
                        (click)="checkIn(personBooking.booking.uuid)"></button>
                <button *ngIf="personBooking.booking.checkedIn" pButton pRipple type="button" label="Auschecken" class="p-button-danger"
                        (click)="checkOut(personBooking.booking.uuid)"></button>
              </div>
              <div class="p-col-6">
                <button pButton pRipple type="button" label="Details" class="p-button-primary"
                        (click)="getPersonDetails(personBooking.person)"></button>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    </p-card>
  </div>
</div>
<p-dialog header="Details Person" [(visible)]="dialogPersonDetailsVisible">
    <app-comp-person-detail [publicKey]="selectedPersonPublicKey"></app-comp-person-detail>
</p-dialog>

