import {Outlet} from './Outlet';
import {Activity} from './Activity';
import {Occupancy} from './Occupancy';
import {Duration} from './Duration';

export class Shift {
  constructor(public uuid: string,
              public outlet: Outlet,
              public activity: Activity,
              public duration: Duration,
              public occupancy: Occupancy,
              public visible: boolean,
              public selected: boolean,
              public bookable: boolean) {
  }
}
