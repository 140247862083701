<div class="p-grid">
  <div class="p-col-12 mb-4">
    <p-card>
      <div class="p-col-12">
        <h3>Schichten bearbeiten</h3>
      </div>
      <div class="p-col-12">
        <p-message *ngIf="loadingError" severity="error" text="Fehler beim Laden der Daten."></p-message>
      </div>
      <div class="p-col-12" align="right">
        <button class="p-button-success" type="button" (click)="openDialogCreateShift()" pButton icon="pi pi-plus" label="Neu"></button>
      </div>
      <div class="p-col-12">
        <p-table [value]="shifts" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th>Standort</th>
              <th>Aktivität</th>
              <th>Zeit</th>
              <th>Anz. Min</th>
              <th>Anz. Max</th>
              <th>Sichtbar</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-shift>
            <tr>
              <td>{{shift.outlet.label}}</td>
              <td>{{shift.activity.label}}</td>
              <td>{{shift.duration.startTime | dateTimeToFormat:'dd.MM.yyyy'}}, {{shift.duration.startTime | dateTimeToFormat:'HH:mm'}} - {{shift.duration.endTime | dateTimeToFormat:'HH:mm'}} Uhr</td>
              <td><input [(ngModel)]="shift.occupancy.min" (change)="updateShift(shift.uuid)" type="number" pInputText class="p-inputtext-sm" placeholder="Small"></td>
              <td><input [(ngModel)]="shift.occupancy.max" (change)="updateShift(shift.uuid)" type="number" pInputText class="p-inputtext-sm" placeholder="Small"></td>
              <td>
                <div class="field-checkbox">
                  <p-checkbox [(ngModel)]="shift.visible" (onChange)="updateShift(shift.uuid)" [binary]="true" inputId="visible"></p-checkbox>
                </div>
              </td>
              <td>
                <button pButton pRipple type="button" class="p-button-danger p-button-sm"
                        [disabled]="shift.occupancy.bookedTotal > 0"
                        icon="pi pi-trash"
                        (click)="deleteShift(shift.uuid)">
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-card>
  </div>
</div>
