import {Component, OnInit} from '@angular/core';
import {Activity} from '../domain/Activity';
import {Outlet} from '../domain/Outlet';
import {Shift} from '../domain/Shift';
import {DateTime} from 'luxon';
import {Day} from '../domain/Day';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Duration} from '../domain/Duration';
import {LocalStorageService} from '../services/local-storage.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-register-overview',
  templateUrl: './register-overview.component.html',
  styleUrls: ['./register-overview.component.css']
})
export class RegisterOverviewComponent implements OnInit {

  isValid = true;
  btnLoading = false;

  eventdate = environment.labels.eventdate;
  eventlabel = environment.labels.eventlabel;

  constructor(private router: Router, private localStorage: LocalStorageService) {
  }

  ngOnInit(): void {
    // this.loadOutlets();
    // this.loadActivities();
  }

  register(): void {
    this.btnLoading = true;
    if (this.localStorage.getSelectedShifts().length === 0) {
      this.isValid = false;
      this.btnLoading = false;
      return;
    }

    this.router.navigate(['/person']);
  }

  shiftChanged(shiftUuids: string[]): void {
    this.localStorage.setSelectedShift(shiftUuids);
    console.log(this.localStorage.getSelectedShifts());
  }
}
