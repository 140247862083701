<app-navigation></app-navigation>
<div class="p-grid">
  <div class="p-col-12">
    <p-card>
      <div class="p-col-12">
        <label>Anzahl Personen: {{persons.length}}</label><br><br>
      </div>
      <div class="p-col-12">
        <input autofocus type="text" pInputText [(ngModel)]="searchTerm" (keyup)="filterPersons()"/>
      </div>
      <div class="p-col-12">
        <p-listbox [options]="filteredPersons" [(ngModel)]="selectedPerson" (onClick)="changePerson($event)"> optionLabel="name">
          <ng-template let-person pTemplate="item">
            <div class="person-item">
              <div class="p-grid">
                <div class="p-sm-3">
                  <app-comp-status-tag [status]="person.minShiftStatus"></app-comp-status-tag>
                </div>
                <div class="p-sm-9">
                  <div>{{person.firstname}} {{person.lastname}}</div>
                </div>
                <div class="p-sm-12" align="pull-right">
                  <p-tag *ngIf="person.shouldSendBookingChangedInfo" severity="warning" value="Änderungen"></p-tag>
                </div>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    </p-card>
  </div>
</div>
