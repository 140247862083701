import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {MegaMenuItem} from 'primeng/api';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  items: MegaMenuItem[] = [];

  activeMenuItem = '1';

  constructor() { }

  ngOnInit(): void {
    this.items = [
      {id: '1', label: 'Personen', icon: 'pi pi-fw pi-users', routerLink: ['/admin-person']},
      {id: '2', label: 'Schichten', icon: 'pi pi-fw pi-list', routerLink: ['/admin-shifts']},
      {id: '3', label: 'Check-In', icon: 'pi pi-fw pi-ticket', routerLink: ['/admin-checkin']},
      {id: '4', label: 'Einstellungen', icon: 'pi pi-fw pi-cog', routerLink: ['/admin-settings']}
    ];
  }
}
