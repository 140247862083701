import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setSelectedShift(uuids: string[]): void {
    localStorage.setItem('shifts', JSON.stringify(uuids));
  }

  getSelectedShifts(): string[] {
    const shifts = localStorage.getItem('shifts');

    if (shifts === null) {
      return [];
    }

    return JSON.parse(shifts);
  }
}
