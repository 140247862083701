<div class="p-grid">
  <div class="p-col-12 p-sm-12 p-md-8 p-md-offset-2 p-lg-8 p-lg-offset-2 p-xl-6 p-xl-offset-3">
    <p-card *ngIf="publicKey && !noPublicKey">
      <div class="p-grid">
        <div class="p-sm-10">
          <h1>Anmeldung {{eventlabel}}</h1>
          <h2>{{eventdate}}</h2>
        </div>
        <div class="p-sm-2">
          <img src="assets/anker.jpg" height="80">
        </div>
      </div>
      <div class="p-grid">
        <div class="p-sm-12">
          <p-message *ngIf="loadingError" severity="error" text="Fehler beim Laden der Daten."></p-message>
          <label>Hier siehst Du deine aktuelle Einteilung. </label>
          <label>Wir möchten Dich bitten deinen Einsatz zu bestätigen.</label>
          <h3>Schichten</h3>
          <p-listbox [options]="bookings">
            <ng-template let-booking pTemplate="item">
              <div class="p-grid">
                <div class="p-col-12">
                  <b>{{booking.shift.duration.startTime | dateTimeToFormat:'dd.MM.yyyy'}}</b>
                </div>
                <div class="p-col-12">
                  {{booking.shift.outlet.label}}
                </div>
                <div class="p-col-12">
                  {{booking.shift.duration.startTime | dateTimeToFormat:'HH:mm'}} - {{booking.shift.duration.endTime | dateTimeToFormat:'HH:mm'}} Uhr
                </div>
                <div class="p-col-12">
                  <p-tag *ngIf="booking.status === 'NEW'" severity="danger" value="Wird zurzeit geprüft..."></p-tag>
                  <p-tag *ngIf="booking.status === 'CONFIRMED_BY_ADMIN'" severity="warning"
                         value="Vom OK bestätigt"></p-tag>
                  <p-tag *ngIf="booking.status === 'CONFIRMED_BY_PERSON'" severity="success"
                         value="Von Dir bestätigt"></p-tag>
                  <p-tag *ngIf="booking.status === 'CHECKED_IN'" value="Eingechecked"></p-tag>
                  <p-tag *ngIf="booking.status === 'UNKNOWN'" severity="danger" value="Unbekannt"></p-tag>
                </div>
                <div class="p-col-12">
                  <button *ngIf="booking.status === 'CONFIRMED_BY_ADMIN'" pButton pRipple type="button"
                          label="Ich bestätige diese Schicht" class="p-button-success p-button-sm"
                          (click)="confirmBooking(booking.uuid)"></button>
                </div>
              </div>
            </ng-template>
          </p-listbox>
        </div>
      </div>
    </p-card>
  </div>
</div>
