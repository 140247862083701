import {Component, OnInit} from '@angular/core';
import {Person} from '../domain/Person';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LocalStorageService} from '../services/local-storage.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.css']
})
export class PersonalDataComponent implements OnInit {

  ageOptions = [{value: true, label: 'Ja'}, {value: false, label: 'Nein'}];
  shirtSizes = [{size: 'UNKNOWN', label: 'Keine Angabe'},
    {size: 'SMALL', label: 'S'},
    {size: 'MEDIUM', label: 'M'},
    {size: 'LARGE', label: 'L'},
    {size: 'XLARGE', label: 'XL'},
    {size: 'XXLARGE', label: 'XXL'}
  ];

  eventdate = environment.labels.eventdate;
  eventlabel = environment.labels.eventlabel;
  emailRepeat = new FormControl();

  person: Person = new Person(undefined, undefined, undefined, undefined, undefined, undefined, false, undefined, undefined, false, undefined, false);

  validityError = {
    firstname: false,
    lastname: false,
    email: false,
	emailRepeat: false,
    noShifts: false,
	uvAccepted: false
  };

  registerError = false;
  registerErrorMessage = '';
  btnLoading = false;

  constructor(private router: Router, private httpClient: HttpClient, private localStorage: LocalStorageService) {
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.router.navigate(['register']);
  }

  register(): void {
    this.btnLoading = true;
    this.validate();
    if (this.validityError.lastname || this.validityError.firstname || this.validityError.email || this.validityError.noShifts || this.validityError.uvAccepted) {
      this.btnLoading = false;
      return;
    }

    const shiftUuids: any = [];
    this.localStorage.getSelectedShifts()?.forEach(shift => {
      shiftUuids.push({shiftUuid: shift});
    });

    const body = {
      firstname: this.person.firstname,
      lastname: this.person.lastname,
      email: this.person.email,
      mobile: this.person.mobile ? this.person.mobile : '',
      over18: this.person.over18,
      shirtSize: this.person.shirtSize,
      comment: this.person.comment,
      shifts: shiftUuids
    };

    this.registerError = false;
    this.httpClient.post<any>(environment.serverUrl + '/registration', body).subscribe(result => {
      this.btnLoading = false;
      this.router.navigate(['/success'], {
        queryParams:
          {
            publicKey: result.publicKey,
          }
      });
    }, error => {
      this.registerError = true;
      this.btnLoading = false;
      this.registerErrorMessage = '';
      if (error.status === 422) {
        this.registerErrorMessage = 'Diese Email-Adresse wurde bereits für eine Anmeldung verwendet. Bitte wähle eine andere Adresse.';
        return;
      }
      if (error.status === 400) {
        this.registerErrorMessage = 'Ungültige Anfrage. Möglicherweise wurden ungültige Schichten ausgewählt.';
        return;
      }
      this.registerErrorMessage = 'Unbekannter Fehler.';
    });
  }

  validate(): void {
    this.validityError.firstname = false;
    this.validityError.lastname = false;
    this.validityError.email = false;
    this.validityError.noShifts = false;
	this.validityError.emailRepeat = false;
	this.validityError.uvAccepted = false;

	this.validityError.uvAccepted = !this.person.acceptedUV;
	this.validityError.firstname = this.person.firstname === undefined;
	this.validityError.lastname = this.person.lastname === undefined;
    this.validityError.email = this.person.email === undefined || !this.person.email?.includes('@') || !this.person.email?.includes('.');

    if (this.localStorage.getSelectedShifts().length === 0) {
      this.validityError.noShifts = true;
    }
	if (this.validityError.emailRepeat = this.person.email !== this.emailRepeat.value) {
		this.validityError.emailRepeat = true;
	}
  }
}
