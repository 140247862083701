import {Shift} from './Shift';
import {DateTime} from 'luxon';

export class Booking {
  constructor(public uuid: string,
              public personUuid: string,
              public shift: Shift,
              public status: string,
              public checkedIn: boolean,
              public deleted: boolean,
              public createdAt: DateTime,
              public updatedAt: DateTime) {
  }
}
