import { Component, OnInit } from '@angular/core';
import {MegaMenuItem} from 'primeng/api';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  activeMenuItem = '1';

  constructor() { }

  ngOnInit(): void {
  }

  changeItem(item: string): void {
    this.activeMenuItem = item;
  }

}
