import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {LocalStorageService} from '../services/local-storage.service';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  btnLoading = false;
  error = false;
  wrongCredentials = false;

  credentials = {
    username: '',
    password: ''
  };

  eventdate = environment.labels.eventdate;
  eventlabel = environment.labels.eventlabel;

  constructor(private httpClient: HttpClient, private router: Router,
              private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  signIn(): void {
    this.btnLoading = true;
    this.error = false;
    this.wrongCredentials = false;
    this.authService.clearSession();

    const body = {
      username: this.credentials.username,
      password: this.credentials.password
    };

    this.httpClient.post<any>(environment.serverUrl + '/users/sign-in', body).subscribe(result => {
        this.btnLoading = false;
        this.authService.setSession(result.jwt, result.expiresSec, body.username);
        this.router.navigate(['/admin']);
      },
      error => {
        if (error.status === 401) {
          this.wrongCredentials = true;
        } else {
          this.error = true;
        }
      });
  }

}
