import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../environments/environment';
import {Person} from '../domain/Person';
import {Shift} from '../domain/Shift';
import {Duration} from '../domain/Duration';
import {DateTime} from 'luxon';
import {RegistrationResponse} from '../domain/RegistrationResponse';
import {Booking} from '../domain/Booking';

@Component({
  selector: 'app-booking-success',
  templateUrl: './booking-success.component.html',
  styleUrls: ['./booking-success.component.css']
})
export class BookingSuccessComponent implements OnInit {

  publicKey: string | undefined;
  person: Person | undefined;
  bookings: Booking[] = [];

  noPublicKey = false;
  loadingError = false;

  eventdate = environment.labels.eventdate;
  eventlabel = environment.labels.eventlabel;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.publicKey = params['publicKey'];
      if (this.publicKey !== undefined) {
        this.loadPerson();
      } else {
        this.noPublicKey = true;
      }
    });
  }

  ngOnInit(): void {
  }

  getShirtSize(): string {
    const size = this.person?.shirtSize;

    if (size === 'SMALL') {
      return 'S';
    }
    if (size === 'MEDIUM') {
      return 'M';
    }
    if (size === 'LARGE') {
      return 'L';
    }
    if (size === 'XLARGE') {
      return 'XL';
    }
    return 'Unbekannt';
  }

  booleanHR(bool: boolean): string {
    if (bool) {
      return 'Ja';
    }
    return 'Nein';
  }

  loadPerson(): void {
    this.loadingError = false;
    this.httpClient.get<RegistrationResponse>(environment.serverUrl + '/registration?publicKey=' + this.publicKey).subscribe(result => {
      this.person = result.person;

      this.bookings = [];

      result.bookings.forEach(re => {
        const shift = new Shift(re.shift.uuid, re.shift.outlet, re.shift.activity,
          new Duration(re.shift.duration.uuid, DateTime.fromISO(re.shift.duration.startTime), DateTime.fromISO(re.shift.duration.endTime)),
          re.shift.occupancy, re.visible, true, true);
        this.bookings.push(new Booking(re.uuid, re.personalUuid, shift, re.status, re.checkedIn, re.deleted,
          DateTime.fromISO(re.createdAt), DateTime.fromISO(re.updatedAt)
          ));
      });
      },
      error => {
        this.loadingError = true;
      });
  }
}
