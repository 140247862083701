<app-navigation></app-navigation>
<div class="p-grid">
  <div *ngFor="let group of groupedShifts" class="p-col-12 mb-4">
    <p-card>
      <div class="p-col-12">
        <h4><label>{{group[0].duration.startTime | dateTimeToFormat:'EEE, HH:mm'}}
          - {{group[0].duration.endTime | dateTimeToFormat:'HH:mm'}} Uhr</label></h4>
        <div *ngFor="let shift of group">
          <div *ngIf="shift" class="p-grid">
            <div *ngIf="shift.outlet" class="p-col-3">
              <label>{{shift.outlet.label}} </label>
            </div>
            <div class="p-col-2">
              <p-progressBar [value]="getOccupancyCheckIn(shift.occupancy)" [showValue]="false"></p-progressBar>
              <label>Eingechecked: {{shift.occupancy.checkedInTotal}} </label>
            </div>
            <div class="p-col-7">
              <p-listbox [options]="getPersonBookingOfShift(shift.uuid)">
                <ng-template let-personBooking pTemplate="personBooking">
                  <div *ngIf="personBooking && personBooking.person && personBooking.booking" class="p-grid">
                      <div class="p-col-1">
                        <i *ngIf="personBooking.booking.checkedIn" class="pi pi-check"></i>
                        <i *ngIf="!personBooking.booking.checkedIn" class="pi pi-times"></i>
                      </div>
                      <div class="p-col-8">
                         {{personBooking.person.firstname}} {{personBooking.person.lastname}}
                      </div>
                      <div class="p-col-3" align="right">
                        <button *ngIf="!personBooking.booking.checkedIn" pButton pRipple type="button"
                                label="Einchecken" class="p-button-warning p-button-sm"
                                (click)="checkIn(personBooking.booking.uuid, shift.uuid)"></button>
                        <button *ngIf="personBooking.booking.checkedIn" pButton pRipple type="button"
                                label="Auschecken" class="p-button-primary p-button-sm"
                                (click)="checkOut(personBooking.booking.uuid, shift.uuid)"></button>
                      </div>
                    </div>
                </ng-template>
              </p-listbox>
            </div>
          </div>
        </div>
      </div>
    </p-card>
  </div>
</div>
