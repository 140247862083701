import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.css']
})
export class AdminSettingsComponent implements OnInit {

  items: MenuItem[] = [];
  activeMenuItem = '1';

  constructor() { }

  ngOnInit(): void {
    this.items = [
      {
        label: 'Einstellungen',
        items: [{
          id: '1',
          label: 'Schichten',
          icon: 'pi pi-fw pi-list',
          command: event => this.activeMenuItem = '1'
        }]
      }
    ];
  }

}
