import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Shift} from '../domain/Shift';
import {LocalStorageService} from '../services/local-storage.service';
import {ShiftChangeStatus} from './ShiftChangeStatus';

@Component({
  selector: 'app-shift-item',
  templateUrl: './shift-item.component.html',
  styleUrls: ['./shift-item.component.css']
})
export class ShiftItemComponent implements OnInit, OnChanges  {

  @Input() public shift: Shift | undefined;
  @Input() public adminView: boolean;

  @Output() public onSelectChange = new EventEmitter<ShiftChangeStatus>();

  constructor() {
    this.adminView = false;
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {

  }

  changeShift(event: any): void {
    if (this.shift === undefined) {
      return;
    }

    if (this.shift.selected){
      this.onSelectChange.emit(new ShiftChangeStatus(this.shift.uuid, true));
    } else {
      this.onSelectChange.emit(new ShiftChangeStatus(this.shift.uuid, false));
    }
  }

  getOccupancy(): any {
    const occ = this.shift?.occupancy;
    if (!occ) {
      return 0;
    }
    return occ.bookedTotal / occ.max * 100.0;
  }

  isFull(): boolean {
    return this.getOccupancy() >= 100.0;
  }

  shouldDisableButton(): boolean {
    return false;

    /*if (this.shift?.selected) {
      return false;
    }

    return !this.shift?.bookable; */
  }
}
