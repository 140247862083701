import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import localeDECH from '@angular/common/locales/de-CH';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RegisterOverviewComponent} from './register-overview/register-overview.component';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ShiftItemComponent} from './shift-item/shift-item.component';
import {ProgressBarModule} from 'primeng/progressbar';
import {LuxonModule} from 'luxon-angular';
import {DatePipe, registerLocaleData} from '@angular/common';
import {ShiftListComponent} from './shift-list/shift-list.component';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {TagModule} from 'primeng/tag';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import {InputTextModule} from 'primeng/inputtext';
import {SelectButtonModule} from 'primeng/selectbutton';
import { BookingSuccessComponent } from './booking-success/booking-success.component';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {PanelModule} from 'primeng/panel';
import {MessageModule} from 'primeng/message';
import { AdminComponent } from './admin/admin.component';
import {MegaMenuModule} from 'primeng/megamenu';
import { AdminShiftsComponent } from './admin-shifts/admin-shifts.component';
import { AdminPersonComponent } from './admin-person/admin-person.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LocalStorageService} from './services/local-storage.service';
import {ListboxModule} from 'primeng/listbox';
import { CompStatusTagComponent } from './comp-status-tag/comp-status-tag.component';
import { PersonBookingComponent } from './person-booking/person-booking.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import {AdminAuthGuard} from './auth/AdminAuthGuard';
import {AuthHttpInterceptor} from './auth/AuthHttpInterceptor';
import {DialogModule} from 'primeng/dialog';
import {CheckboxModule} from 'primeng/checkbox';
import {TimelineModule} from 'primeng/timeline';
import { ShiftListAdminComponent } from './shift-list-admin/shift-list-admin.component';
import { CompPersonDetailComponent } from './comp-person-detail/comp-person-detail.component';
import { AdminCheckinComponent } from './admin-checkin/admin-checkin.component';
import { AdminSettingsShiftComponent } from './admin-settings-shift/admin-settings-shift.component';
import { DialogCreateShiftComponent } from './dialog-create-shift/dialog-create-shift.component';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import {MenuModule} from 'primeng/menu';
import { AdminPersonDetailComponent } from './admin-person-detail/admin-person-detail.component';
import { NavigationComponent } from './navigation/navigation.component';

registerLocaleData(localeDECH);

@NgModule({
  declarations: [
    AppComponent,
    RegisterOverviewComponent,
    ShiftItemComponent,
    ShiftListComponent,
    PersonalDataComponent,
    BookingSuccessComponent,
    AdminComponent,
    AdminShiftsComponent,
    AdminPersonComponent,
    CompStatusTagComponent,
    PersonBookingComponent,
    WelcomeComponent,
    AdminLoginComponent,
    ShiftListAdminComponent,
    CompPersonDetailComponent,
    AdminCheckinComponent,
    AdminSettingsShiftComponent,
    DialogCreateShiftComponent,
    AdminSettingsComponent,
    AdminPersonDetailComponent,
    NavigationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LuxonModule,
    CardModule,
    DropdownModule,
    FormsModule,
    ProgressBarModule,
    TableModule,
    ButtonModule,
    TagModule,
    InputTextModule,
    SelectButtonModule,
    ToggleButtonModule,
    PanelModule,
    MessageModule,
	ReactiveFormsModule,
    MegaMenuModule,
    MenuModule,
    HttpClientModule,
    ListboxModule,
    DialogModule,
    CheckboxModule,
    TimelineModule,
    DynamicDialogModule
  ],
  providers: [DatePipe,
    LocalStorageService,
    AdminAuthGuard,
    {provide: LOCALE_ID, useValue: 'de-ch'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogCreateShiftComponent
  ]
})
export class AppModule {
}
