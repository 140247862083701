import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Shift} from '../domain/Shift';
import { from, of, zip } from 'rxjs';
import { groupBy, map, mergeMap, toArray } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Duration} from '../domain/Duration';
import {DateTime} from 'luxon';
import {Activity} from '../domain/Activity';
import {Outlet} from '../domain/Outlet';
import {Day} from '../domain/Day';
import {HttpClient} from '@angular/common/http';
import {ShiftChangeStatus} from '../shift-item/ShiftChangeStatus';

@Component({
  selector: 'app-shift-list',
  templateUrl: './shift-list.component.html',
  styleUrls: ['./shift-list.component.css']
})
export class ShiftListComponent implements OnInit, OnChanges {

  shifts: Shift[] = [];
  filteredShifts: Shift[] = [];
  selectedShifts: string[] = [];

  onlyFreeShifts = true;

  @Input() public adminView: boolean;
  @Output() public onSelectedShiftChanged = new EventEmitter<string[]>();

  loadingError = false;

  public groupedShifts: any[] = [];

  filterSetting = {
    activityUuid: '-1',
    outletUuid: '-1',
    day: DateTime.now(),
    showFullShifts: false
  };

  activities: Activity[] = [];
  outlets: Outlet[] = [];

  days: Day[] = [
    {label: 'Alle', date: DateTime.now()},
    {label: 'Freitag', date: DateTime.fromISO('2022-07-08')},
    {label: 'Samstag', date: DateTime.fromISO('2022-07-09')},
  ];

  constructor(private httpClient: HttpClient) {
    this.adminView = false;
  }

  ngOnInit(): void {
    this.loadShifts();
  }

  ngOnChanges(): void {
    this.group();
  }

  doFilter(event: any): void {
    this.filterShifts();
  }

  loadShifts(): void {
    this.shifts = [];
    this.filteredShifts = [];
    this.loadingError = false;

    this.selectedShifts = [];
    this.onSelectedShiftChanged.emit(this.selectedShifts);

    this.httpClient.get<any[]>(environment.serverUrl + '/shifts').subscribe(result => {
      this.shifts = [];
      result.forEach(re => {
        this.shifts.push(new Shift(re.uuid, re.outlet, re.activity,
          new Duration(re.duration.uuid, DateTime.fromISO(re.duration.startTime), DateTime.fromISO(re.duration.endTime)),
          re.occupancy, re.visible, false, true));
      });

      this.filterShifts();
    }, error => {
      this.loadingError = true;
    });
  }

  filterShifts(): void {
    this.filteredShifts = [];
    this.shifts.forEach(shift => {

      if (this.onlyFreeShifts) {
        if (!this.filterSetting.showFullShifts && shift.occupancy.bookedTotal >= shift.occupancy.max) {
          return;
        }
      }

      if (this.filterSetting.outletUuid !== '-1' && shift.outlet.uuid !== this.filterSetting.outletUuid) {
        return;
      }

      if (this.filterSetting.activityUuid !== '-1' && shift.activity.uuid !== this.filterSetting.activityUuid) {
        return;
      }

      // if (this.filterSetting.day)

      this.filteredShifts.push(shift);
    });

    this.group();
  }

  private group(): any {
    this.groupedShifts = [];
	var order = ["210", "206", "205", "207", "202", "201", "203", "204", "208", "209"];
    from(this.filteredShifts)
      .pipe(
        groupBy(
          shift => shift.outlet.uuid
        ),
        mergeMap(group => group.pipe(toArray())),
      )
      .subscribe(result => {
		result.sort((a, b) => a.duration.startTime.toMillis() - b.duration.startTime.toMillis())
		this.groupedShifts.push(result);
		this.groupedShifts.sort((a, b) => order.indexOf(a[0].outlet.uuid) - order.indexOf(b[0].outlet.uuid));
	  });
  }

  disableSameTimeShits(): void {
    const bookedShifts: Shift[] = [];
    this.selectedShifts.forEach(shiftUuid => {
      const shift = this.filteredShifts.find(s => s.uuid = shiftUuid);
      if (shift) {
        bookedShifts.push(shift);
      }
    });

    for (const filterShift of this.filteredShifts) {
      for (const bookedShift of this.filteredShifts) {
        if (this.isOverlapping(filterShift, bookedShift)) {
          filterShift.bookable = false;
        }
      }
    }
  }

  isOverlapping(filterShift: Shift, bookedShift: Shift): boolean {
    if (bookedShift.duration.startTime > filterShift.duration.startTime && bookedShift.duration.startTime < filterShift.duration.endTime) {
      return true;
    }
    if (bookedShift.duration.endTime > filterShift.duration.startTime && bookedShift.duration.endTime < filterShift.duration.endTime) {
      return true;
    }
    return false;
  }

  itemChanged(event: ShiftChangeStatus): void {
    if (event.selected){
      this.selectedShifts.push(event.shiftUuid);
    } else {
      this.removeSelectedShift(event.shiftUuid);
    }
    this.onSelectedShiftChanged.emit(this.selectedShifts);
  }

  removeSelectedShift(uuid: string): void {
    const index: number = this.selectedShifts.indexOf(uuid);
    if (index !== -1) {
      this.selectedShifts.splice(index, 1);
    }
  }

  /*optionActivityChanged(event: any): void {
    this.filteredShifts = this.shifts.filter(shift => shift.activity.uuid === this.filterSetting.activityUuid);
  }

  optionOutletChanged(event: any): void {
    this.filteredShifts = [];
    this.filteredShifts = this.shifts.filter(shift => shift.outlet.uuid === this.filterSetting.outletUuid);
  }*/

  /*loadOutlets(): void {
    this.httpClient.get<Outlet[]>(environment.serverUrl + '/shifts/outlets').subscribe(result => {
      this.outlets = result;
      console.log(JSON.stringify(result));
    }, error => {
      // this.outlets = [];
    });
  }

  loadActivities(): void {
    this.httpClient.get<Activity[]>(environment.serverUrl + '/shifts/activities').subscribe(result => {
      this.activities = result;
      console.log(JSON.stringify(result));
    }, error => {
      // this.activities = [];
    });
  }*/

  onlyFreeChanged(): void {
    console.log('here');
    this.filterShifts();
  }
}
