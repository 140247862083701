<div class="p-grid">
  <div class="p-col-12 p-sm-12 p-md-8 p-md-offset-2 p-lg-8 p-lg-offset-2 p-xl-6 p-xl-offset-3">
    <p-card>
      <div class="p-grid">
        <div class="p-sm-10">
          <h1>Admin {{eventlabel}}</h1>
          <h2>{{eventdate}}</h2>
        </div>
        <div class="p-sm-2">
          <img src="assets/anker.jpg" height="80">
        </div>
        <div class="p-sm-12">
          <p-message *ngIf="error" severity="error" text="Fehler beim Login"></p-message>
          <p-message *ngIf="wrongCredentials" severity="error" text="Ungültige Anmeldedaten"></p-message>
        </div>
      </div>
		<div class="grid p-fluid">
		<div class="field p-mb-4 p-mt-4">
			<label for="firstname" class="block">Username</label>
			<input autofocus id="firstname" type="text" [(ngModel)]="credentials.username" pInputText/>
		</div>
		<div class="field p-mb-4 p-mt-4">
			<label for="firstname" class="block">Passwort</label>
			<input autofocus id="password" type="password" [(ngModel)]="credentials.password" pInputText/>
		</div>
		</div>
		<div class="p-grid p-mb-4">
		<div class="p-col-12" align="right">
			<button pButton pRipple type="submit" label="Anmelden" class="p-button-success" (click)="signIn()"></button>
		</div>
		</div>
    </p-card>
  </div>
</div>
