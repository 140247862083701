<div *ngIf="shift">
  <div class="p-grid">
    <div class="p-col-5">
      <label>{{shift.duration.startTime | dateTimeToFormat:'EEE, HH:mm'}} - {{shift.duration.endTime | dateTimeToFormat:'HH:mm'}} Uhr</label>
    </div>
    <div class="p-col-3">
      <p-progressBar [value]="getOccupancy()" [showValue]="false"></p-progressBar>
      <label *ngIf="adminView">Anz: {{shift.occupancy.bookedTotal}} Min: {{shift.occupancy.min}}, Max: {{shift.occupancy.max}} </label>
    </div>
    <div class="p-col-4">
      <p-tag *ngIf="isFull()" styleClass="mr-2" icon="pi pi-times" severity="danger" value="Voll"></p-tag>
      <p-toggleButton *ngIf="!isFull()" [disabled]="shouldDisableButton()" [(ngModel)]="shift.selected" (onChange)="changeShift($event)" onIcon="pi pi-check" offIcon="pi pi-plus" onLabel=" " offLabel="Wählen"></p-toggleButton>
      <!--<button pButton pRipple *ngIf="!shift.selected && !isFull()" type="button" label="Wählen" class="p-button-success p-button-outlined" (click)="selectShift()"></button>
      <p-tag *ngIf="shift.selected" styleClass="mr-2" icon="pi pi-check" severity="success" value="Gewählt"></p-tag>
      <button *ngIf="shift.selected" pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="unselectShift()"></button>-->
    </div>
    </div>
</div>
