import {Injectable} from '@angular/core';
import {HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    // Get the auth token from the printing-service.
    const authToken = this.auth.getToken();

    let headers;
    if (authToken === undefined) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-TenantID': environment.tenantUuid
      });
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + authToken,
        'X-TenantID': environment.tenantUuid
        // 'X-TenantID': environment.tenantUuid
      });
    }

    const authReq = req.clone({headers});

    // send cloned request with header to the next handler.
    return next.handle(authReq).pipe(
      catchError(er => {
        if (er.status === 401 || er.status === 403) {
          this.auth.logout();
        }
        return throwError(er);
      }));
  }
}
