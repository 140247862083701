import {DateTime} from 'luxon';

export class HistoryEntry {
  constructor(public uuid: string,
              public personUuid: string,
              public status: string,
              public emailSent: boolean,
              public success: boolean,
              public errorMessage: string,
              public referenceUuid: string,
              public createdAt: DateTime) {
  }
}
