import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {environment} from '../../environments/environment';
import {Shift} from '../domain/Shift';
import {Duration} from '../domain/Duration';
import {DateTime} from 'luxon';
import {HttpClient} from '@angular/common/http';
import {from} from 'rxjs';
import {groupBy, mergeMap, toArray} from 'rxjs/operators';
import {Occupancy} from '../domain/Occupancy';

@Component({
  selector: 'app-shift-list-admin',
  templateUrl: './shift-list-admin.component.html',
  styleUrls: ['./shift-list-admin.component.css']
})
export class ShiftListAdminComponent implements OnInit {

  @Output() public onSelectedShiftChanged = new EventEmitter<Shift>();

  shifts: Shift[] = [];
  groupedShifts: any[] = [];

  loadingError = false;

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.loadShifts();
  }

  loadShifts(): void {
    this.shifts = [];
    this.loadingError = false;

    this.httpClient.get<any[]>(environment.serverUrl + '/shifts').subscribe(result => {
      this.shifts = [];

      result.forEach(re => {
        this.shifts.push(new Shift(re.uuid, re.outlet, re.activity,
          new Duration(re.duration.uuid, DateTime.fromISO(re.duration.startTime), DateTime.fromISO(re.duration.endTime)),
          new Occupancy(re.occupancy.bookedTotal, re.occupancy.checkedInTotal, new Map(Object.entries(re.occupancy.numbersByStatus)),
            re.occupancy.min, re.occupancy.max), re.visible, false, true));
      });

      this.groupShifts();

    }, error => {
      this.loadingError = true;
    });
  }

  isFull(occ: Occupancy): boolean {
    return this.getOccupancy(occ) >= 100.0;
  }

  getOccupancy(occ: Occupancy): any {
    if (!occ) {
      return 0;
    }
    return Math.round(occ.bookedTotal / occ.max * 100.0 * 10.0) / 10.0;
  }

  getOccupancyCheckIn(occ: Occupancy): any {
    if (!occ) {
      return 0;
    }
    return Math.round(occ.checkedInTotal / occ.max * 100.0 * 10.0) / 10.0;
  }

  getOccupancyOf(occ: Occupancy, status: string): any {
    if (!occ) {
      return 0;
    }
    let numberInStatus = occ.numbersByStatus.get(status);
    if (!numberInStatus) {
      numberInStatus = 0;
    }
    return Math.round(numberInStatus / occ.max * 100.0 * 10.0) / 10.0;
  }

  selectShiftDetail(shift: Shift): void {
    this.onSelectedShiftChanged.emit(shift);
  }

  private groupShifts(): any {
    this.groupedShifts = [];
	var order = ["210", "206", "205", "207", "202", "201", "203", "204", "208", "209"];
    from(this.shifts)
      .pipe(
        groupBy(
          shift => shift.outlet.uuid
        ),
        mergeMap(group => group.pipe(toArray()))
      )
      .subscribe(result => {
		this.groupedShifts.push(result)
		this.groupedShifts.sort((a, b) => order.indexOf(a[0].outlet.uuid) - order.indexOf(b[0].outlet.uuid));
	});
  }
}
