import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  eventdate = environment.labels.eventdate;
  eventlabel = environment.labels.eventlabel;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  go(): void {
    this.router.navigate(['/register']);
  }
}
