import { Component, OnInit } from '@angular/core';
import {Shift} from '../domain/Shift';
import {Day} from '../domain/Day';
import {DateTime} from 'luxon';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Person} from '../domain/Person';
import {Router} from '@angular/router';
import {PersonBooking} from '../domain/PersonBooking';

@Component({
  selector: 'app-admin-shifts',
  templateUrl: './admin-shifts.component.html',
  styleUrls: ['./admin-shifts.component.css']
})
export class AdminShiftsComponent implements OnInit {

  selectedShift: Shift | undefined;
  personBookings: PersonBooking[] = [];

  dialogPersonDetailsVisible = false;
  selectedPersonPublicKey: string | undefined;

  constructor(private httpClient: HttpClient, private router: Router) { }

  ngOnInit(): void {
  }

  selectedShiftChange(shift: Shift): void {

    if (shift === undefined) {
      return;
    }

    this.selectedShift = shift;
    this.loadShiftDetails();

  }

  loadShiftDetails(): void {
    this.personBookings = [];

    if (this.selectedShift === undefined) {
      return;
    }

    this.httpClient.get<PersonBooking[]>(environment.serverUrl + '/registration/of-shift?shiftUuid=' + this.selectedShift.uuid).subscribe(
      result => {
        this.personBookings = result;
      }, error => {
        // ToDo
      }
    );
  }

  checkIn(bookingUuid: string): void {
    this.httpClient.post<any>(environment.serverUrl + '/registration/' + bookingUuid + '/check-in', {}).subscribe(
      result => this.loadShiftDetails(),
      error => this.loadShiftDetails()
    );
  }

  checkOut(bookingUuid: string): void {
    this.httpClient.post<any>(environment.serverUrl + '/registration/' + bookingUuid + '/check-out', {}).subscribe(
      result => this.loadShiftDetails(),
      error => this.loadShiftDetails()
    );
  }

  getPersonDetails(person: Person): void {
    this.selectedPersonPublicKey = person.publicKey;
    this.dialogPersonDetailsVisible = true;
  }

  booleanHR(bool: boolean): string {
    if (bool) {
      return 'Ja';
    }
    return 'Nein';
  }
}
