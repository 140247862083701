<div class="p-grid">
  <!--<div class="p-col-12 p-sm-12 p-md-8 p-md-offset-2 p-lg-8 p-lg-offset-2 p-xl-6 p-xl-offset-3">
    <p-card>
      <div class="p-grid">
        <div class="p-col-12">
          <h3>Filter</h3>
        </div>
        <div class="p-col-4">
          <h5>Tag</h5>
          <p-dropdown [options]="days" [(ngModel)]="filterSetting.day"  (onChange)="doFilter($event)"
                      optionLabel="label" optionValue="uuid"></p-dropdown>
        </div>
        <div class="p-col-4">
          <h5>Tätigkeit</h5>
          <p-dropdown [options]="activities" [(ngModel)]="filterSetting.activityUuid" (onChange)="doFilter($event)"
                      optionLabel="label" optionValue="uuid"></p-dropdown>
        </div>
        <div class="p-col-4">
          <h5>Ort</h5>
          <p-dropdown [options]="outlets" [(ngModel)]="filterSetting.outletUuid" (onChange)="doFilter($event)"
                      optionLabel="label" optionValue="uuid"></p-dropdown>
        </div>
      </div>
    </p-card>
  </div>-->
  <div class="p-col-12 p-sm-12 p-md-8 p-md-offset-2 p-lg-8 p-lg-offset-2 p-xl-6 p-xl-offset-3">
    <p-card>
      <div class="p-grid">
        <div class="p-sm-10">
          <h1>Schichten {{eventlabel}}</h1>
          <h2>{{eventdate}}</h2>
        </div>
        <div class="p-sm-2">
          <img src="assets/anker.jpg" height="80">
        </div>
      </div>
      <div class="p-col-12">
        <app-shift-list [adminView]="false" (onSelectedShiftChanged)="shiftChanged($event)"></app-shift-list>
      </div>
      <div class="p-grid">
        <div class="p-col-12" align="right">
          <br>
          <button pButton pRipple type="button" label="Weiter" class="p-button-success"
                  (click)="register()" [loading]="btnLoading"></button>
          <br><br>
          <p-message *ngIf="!isValid" severity="error" text="Bitte wähle mindestens eine Schicht."></p-message>
        </div>
      </div>
    </p-card>
  </div>
</div>
