import {Component, OnInit} from '@angular/core';
import {Person} from '../domain/Person';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {RegistrationResponse} from '../domain/RegistrationResponse';
import {Shift} from '../domain/Shift';
import {Duration} from '../domain/Duration';
import {DateTime} from 'luxon';
import {Booking} from '../domain/Booking';
import {HistoryEntry} from '../domain/HistoryEntry';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin-person',
  templateUrl: './admin-person.component.html',
  styleUrls: ['./admin-person.component.css']
})
export class AdminPersonComponent implements OnInit {

  searchTerm = '';

  persons: Person[] = [];
  filteredPersons: Person[] = [];

  selectedPerson: Person | undefined;

  constructor(private httpClient: HttpClient, private router: Router) {
  }

  ngOnInit(): void {
    this.loadPersons();
  }

  loadPersons(): void {
    this.httpClient.get<Person[]>(environment.serverUrl + '/persons').subscribe(result => {
      this.persons = result;
      this.filterPersons();
    }, error => {
      // Todo
    });
  }

  changePerson(event: any): void {
    this.router.navigate(['/admin-person-detail'], {
      queryParams:
        {
          uuid: event.value.publicKey
        }
    });

  }

  filterPersons(): void {
    if (this.searchTerm === '') {
      this.filteredPersons = this.persons;
      return;
    }

    const term = this.searchTerm.toLowerCase();
    this.filteredPersons = this.persons.filter(person =>
      person.firstname?.toLowerCase().includes(term) || person.lastname?.toLowerCase().includes(term) ||
      person.email?.toLowerCase().includes(term) || person.mobile?.toLowerCase().includes(term)
    );
  }
}
