import {Component, OnInit} from '@angular/core';
import {Shift} from '../domain/Shift';
import {environment} from '../../environments/environment';
import {Duration} from '../domain/Duration';
import {DateTime} from 'luxon';
import {HttpClient} from '@angular/common/http';
import {DialogService} from 'primeng/dynamicdialog';
import {DialogCreateShiftComponent} from '../dialog-create-shift/dialog-create-shift.component';

@Component({
  selector: 'app-admin-settings-shift',
  templateUrl: './admin-settings-shift.component.html',
  styleUrls: ['./admin-settings-shift.component.css'],
  providers: [DialogService]
})
export class AdminSettingsShiftComponent implements OnInit {

  loadingError = false;

  shifts: Shift[] = [];

  constructor(private httpClient: HttpClient, public dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.loadShifts();
  }

  loadShifts(): void {
    this.shifts = [];
    this.loadingError = false;

    this.httpClient.get<any[]>(environment.serverUrl + '/shifts?showInvisible=true').subscribe(result => {
      this.shifts = [];

      result.forEach(re => {
        this.shifts.push(new Shift(re.uuid, re.outlet, re.activity,
          new Duration(re.duration.uuid, DateTime.fromISO(re.duration.startTime), DateTime.fromISO(re.duration.endTime)),
          re.occupancy, re.visible, false, true));
      });
    }, error => {
      this.loadingError = true;
    });
  }

  openDialogCreateShift(): void {
    const ref = this.dialogService.open(DialogCreateShiftComponent, {
      header: 'Neue Schicht',
      width: '70%'
    });

    ref.onClose.subscribe(() => {
      this.loadShifts();
    });
  }

  updateShift(shiftUuid: string): void {
    const currentShift = this.shifts.find(s => s.uuid === shiftUuid);
    if (!currentShift) {
      return;
    }

    const body = {
      occupancyMin: currentShift.occupancy.min,
      occupancyMax: currentShift.occupancy.max,
      visible: currentShift.visible
    };

    this.httpClient.post<any>(environment.serverUrl + '/shifts/' + shiftUuid, body).subscribe(
      result => this.loadShifts(),
      error => {
        this.loadingError = true;
        this.loadShifts();
      }
    );
  }

  deleteShift(shiftUuid: string): void {
    this.httpClient.delete<any>(environment.serverUrl + '/shifts/' + shiftUuid).subscribe(
      result => this.loadShifts(),
      error => {
        this.loadingError = true;
      }
    );
  }
}
