export class Person {
  constructor(
    public uuid: string | undefined,
    public publicKey: string | undefined,
    public firstname: string | undefined,
    public lastname: string | undefined,
    public email: string | undefined,
    public mobile: string | undefined,
    public over18: boolean,
    public shirtSize: string | undefined,
    public minShiftStatus: string | undefined,
    public shouldSendBookingChangedInfo: boolean,
    public comment: string | undefined,
	public acceptedUV: boolean,
  ) {
  }
}
